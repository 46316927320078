<template lang="pug">
.layoutFull.s-layout.s-flex-direction--column
  main.s-expand.s-flex-flex-direction--column-items--center-content--center.s-swatch-black--fill
    header.s-padding-y--s.s-position--top--center.s-width--expand.backHome.s-swatch-black.s-text-align--center
      a.s-text--caps-size--xxs.s-comment(href="/") Return to home page#[span.icon-arrow-right-s-line.s-margin-left--xxs]
    router-view
  footerMain
</template>
<script>
import footerMain from '@/components/footerMain.vue'

export default {
  components: {
    footerMain
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
